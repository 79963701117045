import cn from 'classnames';
import { Grid, ColSize } from './interface';
import { getClassName } from './utils';

export interface ContainerProps extends Grid {
  size?: ColSize;
}

const Container: React.FC<ContainerProps> = ({
  size,
  offset,
  g,
  gx,
  gy,
  className,
  style,
  children,
  ...props
}) => (
  <div
    className={cn(`container`, className, {
      [`container-${size}`]: size,
      ...getClassName({ offset, g, gx, gy }),
    })}
    style={style}
    {...props}
  >
    {children}
  </div>
);

export default Container;
